import { IFilterItem } from '@/types/filters/filters';

export interface IPlanItem {
  id: string;
  status: EPlanStatus;
  validationStatus: EPlanValidationStatus;
  name: string;
  description?: string;
  active: boolean;
  createdAt: string;
  processedAt: string;
  comment: string;
}

export enum EPlanValidationStatus {
  newPlan = 'Новый',
  processed = 'Рассчитан',
  readyPlan = 'Готов',
  canceled = 'Отклонен',
  error = 'Ошибка',
}

export enum EPlanStatus {
  newPlan = 'Новый',
  error = 'Ошибка расчета',
  calculate = 'Расчет',
  ready = 'Рассчитан',
}

export type TGetPlansParams = {
  sort?: string;
  order?: string;
  offset: number;
  limit: number;
};

export type TGetPlansData = {
  filter: IFilterItem[];
  query?: string;
};

export interface IPlansCommentParams {
  id: string[];
  comment: string;
  isMultiple?: boolean;
}

export interface IPlanChangeActive {
  id: string;
  value: boolean;
}

export interface IPlansChangeStatus {
  id: string[];
  status: string;
}

export enum EPlansChangeValues {
  published = 'ready',
  declined = 'declined',
  processed = 'processed',
}

export interface IPlanData {
  name: string;
  priceAlg: 'old_price' | 'min' | 'max' | 'next_range' | '';
  description: string | undefined;
  comment: string | undefined;
}

export interface IPlanEditData {
  data: IPlanData;
  id: string;
}

export interface IPlanAddPairs {
  id: string;
  geo: string[];
  prod: {
    id: number;
    level: number;
  }[];
}

export interface IPlanGeoItem {
  name: string;
  id: string;
}

export interface IPlanProdItem extends IPlanGeoItem {
  level: number;
  isParent: boolean;
}

export interface IPlanPairs {
  prodItem: IPlanProdItem;
  geoItem: IPlanGeoItem;
  child?: IPlanPairs[];
}

export interface IPlanDeletePairs {
  id: string;
  data: {
    prodItem: { id: string; level: number };
    geoItem: string;
  }[];
}

export interface IGetPlanPairs {
  id: string;
  parentProduct?: string;
  parentGeo?: string;
}

export interface IPlanInfoTitle {
  id: string;
  status: EPlanStatus;
  validationStatus: EPlanValidationStatus;
  name: string;
  description: string;
  active: boolean;
  createdAt: string;
  processedAt?: string;
  comment?: string;
  productsCount: number;
  regionsCount: number;
  pairsCount: number;
  pairsWithErrors?: number;
  approved_at?: string;
  increasedPrices?: number;
  decreasedPrices?: number;
  changedPrices?: number;
  turnover?: number;
  estimatedTurnover?: number;
  estimatedProfit?: number;
  priceAlg: PriceAlg;
}

export interface IPlanEditItem {
  id: string;
  name: string;
  prodName: string;
  prodId: string;
  geoName: string;
  geoId: string;
  status: EPlanChangeItemValues;
  profitability?: number;
  cost?: number;
  costWithBonus?: number;
  currentPrice?: number;
  newPrice?: number;
  lockPrice?: number;
  newPriceFrom?: number;
  newPriceTo?: number;
  errorsCount?: number;
  priceChangePercent?: number;
  priceChangePercentMod?: number;
  currentMarkup?: number;
  currentMarkupWithBonus?: number;
  newMarkup?: number;
  newMarkupWithBonus?: number;
  changeMarkup?: number;
  changeMarkupAbs?: number;
  changeMarkupWithBonus?: number;
  changeMarkupWithBonusAbs?: number;
  sales?: number;
  turnover?: number;
  estimatedTurnover?: number;
  estimatedTurnoverGrowth?: number;
  comment?: string;
  priceChangeAbs?: number;
  priceChangeAbsMod?: number;
  jnvls: boolean;
  priceBeforePostProcessing: number | null;
  maxRetailPriceJnvls: number | null;
  minPriceRuleValueFrom: number | null;
  minPriceRuleValueTo: number | null;
  tradeMark?: string;
  class?: string;
  costs_type?: boolean | null;
  competitor_type?: boolean | null;
  fix_prices_type?: boolean | null;
  fix_margin_type?: boolean | null;
  assortment_type?: boolean | null;
  old_prices_type?: boolean | null;
  jnvls_type?: boolean | null;
}

export type TGetPlanDetailData = {
  id: string;
  filter: IFilterItem[];
} & TGetPlansParams;

export interface IGetPlanDetail {
  items: IPlanEditItem[];
  total: number;
}

export enum EPlanChangeItemValues {
  error = 'error',
  waiting = 'waiting',
  approved = 'approved',
  declined = 'declined',
}

export interface IPlanDetailPairInfo {
  lines?: IPlanDetailValues[];
  rules?: IPlanDetailPairInfoRules[];
  notAppliedRules?: IPlanDetailPairInfoRules[] | null;
}

export interface IPlanDetailValues {
  name: string;
  value: number;
}

export interface IPlanDetailPairInfoRules {
  name: string;
  range: [number, number];
  description: string;
}

export interface IPlanDetailPriceInfo {
  points?: IPlanDetailPriceInfoPoints[];
}

export interface IPlanDetailPriceInfoPoints {
  date: number;
  values: IPlanDetailPriceInfoPointsValues[];
}

export interface IPlanDetailPriceInfoPointsValues extends IPlanDetailValues {
  isAnomaly?: boolean;
}

export interface IPlanPriceDataValues {
  date: number;
  values: IPlanDetailValues[];
}

export interface IPlanPriceDotsValues {
  date: number;
  value: number;
  isAnomaly: boolean;
}

export interface PlanDetailStatusChange {
  id: string;
  status: string;
  values: string[];
}

export enum PriceAlg {
  oldPrice = 'old_price',
  minPrice = 'min',
  maxPrice = 'max',
  nextRange = 'next_range',
}

export enum PriceAlgTitle {
  'old_price' = 'Минимизировать изменение текущей цены',
  'min' = 'Минимальное значение доступного диапазона цены',
  'max' = 'Максимальное значение доступного диапазона цены',
  'next_range' = 'Ближайшая цена к следующему диапазону цен после доступного',
}

export interface PlanDetailExport {
  id: string;
  sort?: string;
  order?: string;
}
