import { ERuleType, ICompetitorRule, ICompetitorRuleValues } from '@/types/rules/rules';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Select,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconChevronDown, IconChevronUp, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import _ from 'lodash';

import {
  emptyValuesValidation,
  getCompetitorRuleInitialValues,
  modifiedCompetitorRule,
  positiveNumbersValidation,
} from '@/utils/forms';
import { modifiedNodeParams } from '@/utils/rules';

import { rulesInitialValues } from '@/constants/rules';

import { openDeleteModal, useStyles } from '../FixPriceRule/FixPriceRule';
import PriceRuleBlock from '../PriceRuleBlock/PriceRuleBlock';

import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeleteRuleAction,
  fetchRuleEditAction,
  fetchRulesListAction,
  selectCompetitorAggregations,
  selectRuleBlocks,
  setCompetitorRule,
  setCurrentRule,
  setFormsCommentModalOpened,
  setRuleBlocks,
} from '@/store/slices/rules/rules';

interface CompetitorRuleProps {
  competitorRule: ICompetitorRule;
  alsoHasInheritedRule?: boolean;
}

const CompetitorRule: FC<CompetitorRuleProps> = ({ alsoHasInheritedRule, competitorRule }) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const competitorAggregations = useSelector(selectCompetitorAggregations);
  const ruleBlocks = useSelector(selectRuleBlocks);
  const nodesParams = useSelector(selectNodesParams);

  const [disabled, setDisabled] = useState<boolean>(competitorRule?.inherited ?? false);

  const isSeveralCompetitors = {
    display: competitorRule.values.length >= 2 ? 'inline-block' : 'none',
  };

  const modifiedCompetitorAggregations = competitorAggregations.map((item) => ({
    value: item.type,
    label: item.name,
  }));

  const form = useForm({
    initialValues: {
      fields: getCompetitorRuleInitialValues(competitorRule?.values ?? []),
    },
    validate: {
      fields: {
        competitorBrand: (value) => emptyValuesValidation(value),
        values: {
          priceFrom: (value) => positiveNumbersValidation(value, true),
          priceTo: (value) => positiveNumbersValidation(value, true),
        },
      },
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (formData: { fields: ICompetitorRuleValues[] }) => {
    if (competitorRule) {
      const newValues = formData.fields.map((item) => ({
        competitorBrand: item.competitorBrand,
        values: item.values.map((valueItem) => ({
          priceFrom: Number(valueItem.priceFrom),
          priceTo: Number(valueItem.priceTo),
          fromPercent: valueItem.fromPercent ? Number(valueItem.fromPercent) : null,
          fromValue: valueItem.fromValue ? Number(valueItem.fromValue) : null,
          toPercent: valueItem.toPercent ? Number(valueItem.toPercent) : null,
          toValue: valueItem.toValue ? Number(valueItem.toValue) : null,
        })),
      }));

      const updatedCompetitorRule = {
        ...competitorRule,
        values: newValues,
      };

      dispatch(setCompetitorRule(updatedCompetitorRule));
      dispatch(setCurrentRule(updatedCompetitorRule));
      dispatch(setFormsCommentModalOpened(true));
    }
  };

  const mixDataDown = (competitorIndex: number) => {
    if (competitorRule)
      if (competitorIndex === competitorRule?.values.length - 1) {
        form.reorderListItem('fields', {
          from: competitorIndex,
          to: 0,
        });
      }

    form.reorderListItem('fields', {
      from: competitorIndex,
      to: competitorIndex + 1,
    });
  };

  const mixDataUp = (competitorIndex: number) => {
    if (competitorRule)
      if (competitorIndex === 0) {
        form.reorderListItem('fields', {
          from: competitorIndex,
          to: competitorRule?.values.length - 1,
        });
      }

    form.reorderListItem('fields', {
      from: competitorIndex,
      to: competitorIndex - 1,
    });
  };

  const changeActiveStatus = async (status: boolean) => {
    if (competitorRule) {
      const updatedCompetitorRule = {
        ...competitorRule,
        active: status,
      };
      dispatch(setCurrentRule(updatedCompetitorRule));
      dispatch(setCompetitorRule(updatedCompetitorRule));

      await dispatch(
        fetchRuleEditAction({
          nodes: modifiedNodeParams(nodesParams),
          rule: modifiedCompetitorRule(status, competitorRule),
        }),
      );

      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }
  };

  const addValue = (competitorIndex: number) => {
    if (competitorRule) {
      const newValues = competitorRule.values.map((item, index) => ({
        competitorBrand: item.competitorBrand,
        values:
          index === competitorIndex
            ? [...item.values, { ...rulesInitialValues.competitorRule }]
            : item.values,
      }));

      dispatch(
        setCompetitorRule({
          ...competitorRule,
          values: newValues,
        }),
      );

      form.insertListItem(`fields.${competitorIndex}.values`, {
        ...rulesInitialValues.competitorRule,
      });
    }
  };

  const removeValue = (competitorIndex: number, valueIndex: number) => {
    if (competitorRule) {
      if (competitorRule?.values[competitorIndex].values.length !== 1) {
        const newValues = competitorRule.values.map((item, index) => ({
          competitorBrand: item.competitorBrand,
          values:
            index === competitorIndex
              ? item.values.filter(
                  (competitorValueItem, competitorValueIndex) =>
                    competitorValueIndex !== valueIndex,
                )
              : item.values,
        }));

        dispatch(
          setCompetitorRule({
            ...competitorRule,
            values: newValues,
          }),
        );

        form.removeListItem(`fields.${competitorIndex}.values`, valueIndex);
      } else {
        form.setFieldValue(`fields.${competitorIndex}.values.0.priceFrom`, 0);
        form.setFieldValue(`fields.${competitorIndex}.values.0.priceTo`, 999999);
        form.setFieldValue(`fields.${competitorIndex}.values.0.fromPercent`, '');
        form.setFieldValue(`fields.${competitorIndex}.values.0.fromValue`, '');
        form.setFieldValue(`fields.${competitorIndex}.values.0.toPercent`, '');
        form.setFieldValue(`fields.${competitorIndex}.values.0.toValue`, '');
      }
    }
  };

  const addCompetitor = () => {
    if (competitorRule) {
      const newCompetitor = {
        competitorBrand: '',
        values: [{ ...rulesInitialValues.competitorRule }],
      };

      dispatch(
        setCompetitorRule({
          ...competitorRule,
          values: [...competitorRule.values, newCompetitor],
        }),
      );

      form.insertListItem('fields', _.cloneDeep(newCompetitor));
    }
  };

  const removeCompetitor = (competitorIndex: number) => {
    if (competitorRule) {
      if (competitorRule?.values.length !== 1) {
        const newValues = competitorRule.values.filter((item, index) => index !== competitorIndex);

        dispatch(
          setCompetitorRule({
            ...competitorRule,
            values: newValues,
          }),
        );

        form.removeListItem('fields', competitorIndex);
      }
    }
  };

  const deleteRule = async () => {
    if (competitorRule?.id) {
      await dispatch(fetchDeleteRuleAction([competitorRule.id]));
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }

    dispatch(setCompetitorRule(null));
    const updatedRuleBlocks = ruleBlocks.map((item) => ({
      ...item,
      checked: item.type === ERuleType.competitor ? false : item.checked,
      shown: item.type === ERuleType.competitor ? false : item.shown,
    }));

    dispatch(setRuleBlocks(updatedRuleBlocks));
  };

  return (
    <PriceRuleBlock>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="row" justify="space-between">
          {competitorRule?.inherited || alsoHasInheritedRule ? (
            <Badge color="green" variant="filled">
              Наследуется
            </Badge>
          ) : (
            <Switch
              classNames={{ track: classes.track }}
              checked={competitorRule?.active ?? false}
              color="dark"
              size="md"
              onChange={(event) => {
                changeActiveStatus(event.currentTarget.checked);
              }}
            />
          )}
          {!(alsoHasInheritedRule || competitorRule?.inherited) && (
            <ActionIcon
              color="dark"
              size="lg"
              variant="transparent"
              className={classes.actionIcon}
              onClick={() => openDeleteModal(deleteRule)}
            >
              <IconX />
            </ActionIcon>
          )}
        </Flex>
        <Title mt={12} mb={24} order={3}>
          {competitorRule?.name}
        </Title>
        {competitorRule?.values?.map((competitor, competitorIndex) => (
          <Box key={competitorIndex}>
            <Flex direction="column">
              <Flex direction="row" align="flex-start">
                <UnstyledButton
                  style={isSeveralCompetitors}
                  mr={16}
                  mt={22}
                  onClick={() => {
                    mixDataDown(competitorIndex);
                  }}
                >
                  <IconChevronDown />
                </UnstyledButton>
                <UnstyledButton
                  style={isSeveralCompetitors}
                  mr={24}
                  mt={22}
                  onClick={() => {
                    mixDataUp(competitorIndex);
                  }}
                >
                  <IconChevronUp />
                </UnstyledButton>
                <Flex direction="column">
                  <Flex direction="row" align="center" gap={24}>
                    <Select
                      w={380}
                      mb={24}
                      label="Бренд конкурента"
                      rightSection={<IconChevronDown size="1rem" />}
                      data={modifiedCompetitorAggregations}
                      disabled={!competitorRule.active || disabled}
                      required
                      {...form.getInputProps(`fields.${competitorIndex}.competitorBrand`)}
                    />
                    <Button
                      color="gray.2"
                      variant="filled"
                      style={isSeveralCompetitors}
                      onClick={() => removeCompetitor(competitorIndex)}
                    >
                      Удалить конкурента
                    </Button>
                  </Flex>
                  {competitorRule?.values &&
                    competitorRule?.values[competitorIndex].values.map((value, valueIndex) => (
                      <Flex direction="row" gap={24} align="flex-end" key={valueIndex}>
                        <TextInput
                          classNames={{ label: classes.label, rightSection: classes.rightSection }}
                          label="РЦ"
                          icon={<Text>от</Text>}
                          rightSection="₽"
                          disabled={!competitorRule.active || disabled}
                          required
                          {...form.getInputProps(
                            `fields.${competitorIndex}.values.${valueIndex}.priceFrom`,
                          )}
                        />
                        <TextInput
                          classNames={{ label: classes.label, rightSection: classes.rightSection }}
                          icon={<Text>до</Text>}
                          rightSection="₽"
                          disabled={!competitorRule.active || disabled}
                          required
                          {...form.getInputProps(
                            `fields.${competitorIndex}.values.${valueIndex}.priceTo`,
                          )}
                        />
                        <TextInput
                          classNames={{ label: classes.label, rightSection: classes.rightSection }}
                          label="Левая граница"
                          icon={<Text>А</Text>}
                          rightSection="%"
                          disabled={!competitorRule.active || disabled}
                          {...form.getInputProps(
                            `fields.${competitorIndex}.values.${valueIndex}.fromPercent`,
                          )}
                        />
                        <TextInput
                          classNames={{ label: classes.label, rightSection: classes.rightSection }}
                          icon={<Text>В</Text>}
                          rightSection="₽"
                          disabled={!competitorRule.active || disabled}
                          {...form.getInputProps(
                            `fields.${competitorIndex}.values.${valueIndex}.fromValue`,
                          )}
                        />
                        <TextInput
                          classNames={{ label: classes.label, rightSection: classes.rightSection }}
                          label="Правая граница"
                          icon={<Text>А</Text>}
                          rightSection="%"
                          disabled={!competitorRule.active || disabled}
                          {...form.getInputProps(
                            `fields.${competitorIndex}.values.${valueIndex}.toPercent`,
                          )}
                        />
                        <TextInput
                          classNames={{ label: classes.label, rightSection: classes.rightSection }}
                          rightSection="₽"
                          icon={<Text>В</Text>}
                          disabled={!competitorRule.active || disabled}
                          {...form.getInputProps(
                            `fields.${competitorIndex}.values.${valueIndex}.toValue`,
                          )}
                        />
                        <ActionIcon
                          color="dark"
                          size="lg"
                          variant="transparent"
                          className={classes.actionIcon}
                          disabled={!competitorRule.active || disabled}
                          onClick={() => removeValue(competitorIndex, valueIndex)}
                          title="Очистить"
                        >
                          <IconTrash />
                        </ActionIcon>
                      </Flex>
                    ))}

                  <Flex justify="flex-start" mt={24}>
                    <Button
                      sx={(theme) => ({
                        color: competitorRule.active || !disabled ? theme.colors.blue[8] : 'gray',
                        paddingLeft: '0px',
                        ['&[data-disabled]']: {
                          backgroundColor: 'white',
                        },
                      })}
                      variant="subtle"
                      leftIcon={<IconPlus size="1.3rem" />}
                      disabled={!competitorRule.active || disabled}
                      onClick={() => addValue(competitorIndex)}
                    >
                      Добавить ещё
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
              <Divider
                sx={(theme) => ({ margin: '24px 0px', borderTopColor: theme.colors.gray[2] })}
              />
            </Flex>
          </Box>
        ))}

        <Flex direction="row" justify="space-between">
          <Button
            sx={(theme) => ({
              color: competitorRule?.active || !disabled ? theme.colors.blue[8] : 'gray',
              paddingLeft: '0px',
              ['&[data-disabled]']: {
                backgroundColor: 'white',
              },
            })}
            variant="subtle"
            leftIcon={<IconPlus size="1.3rem" />}
            disabled={!competitorRule?.active || disabled}
            onClick={addCompetitor}
          >
            Добавить конкурента
          </Button>
          {alsoHasInheritedRule && (
            <Tooltip
              label="Вы не можете редактировать правило, так как уже создано деактивированное правило. Перейдите во вкладку деактивированных правил."
              withArrow
              multiline
              w={350}
              openDelay={500}
              position="left"
            >
              <Flex>
                <Button color="dark.9" disabled={alsoHasInheritedRule}>
                  Редактировать
                </Button>
              </Flex>
            </Tooltip>
          )}
          {competitorRule?.active && disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} onClick={() => setDisabled(false)}>
              Редактировать
            </Button>
          )}
          {competitorRule?.active && !disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} type="submit" disabled={!form.isValid()}>
              Сохранить
            </Button>
          )}
        </Flex>
      </form>
    </PriceRuleBlock>
  );
};

export default CompetitorRule;
