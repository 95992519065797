import { ERuleType, IMinPriceChangeRule, IMinPriceChangeRuleValues } from '@/types/rules/rules';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPlus, IconTrash, IconX } from '@tabler/icons-react';

import {
  getMinPriceChangeRuleInitialValues,
  modifiedMinPriceChangeRule,
  positiveNumbersValidation,
} from '@/utils/forms';
import { modifiedNodeParams } from '@/utils/rules';

import { rulesInitialValues } from '@/constants/rules';

import { openDeleteModal, useStyles } from '../FixPriceRule/FixPriceRule';
import PriceRuleBlock from '../PriceRuleBlock/PriceRuleBlock';

import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeleteRuleAction,
  fetchRuleEditAction,
  fetchRulesListAction,
  selectRuleBlocks,
  setCurrentRule,
  setFormsCommentModalOpened,
  setMinPriceChangeRule,
  setRuleBlocks,
} from '@/store/slices/rules/rules';

interface MinPriceChangeRuleProps {
  minPriceChangeRule: IMinPriceChangeRule;
  alsoHasInheritedRule?: boolean;
}

const MinPriceChangeRule: FC<MinPriceChangeRuleProps> = ({
  alsoHasInheritedRule,
  minPriceChangeRule,
}) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const ruleBlocks = useSelector(selectRuleBlocks);
  const nodesParams = useSelector(selectNodesParams);

  const [disabled, setDisabled] = useState<boolean>(minPriceChangeRule?.inherited ?? false);

  const form = useForm({
    initialValues: {
      fields: getMinPriceChangeRuleInitialValues(minPriceChangeRule?.values ?? []),
    },
    validate: {
      fields: {
        priceFrom: (value) => positiveNumbersValidation(value, true),
        priceTo: (value) => positiveNumbersValidation(value, true),
      },
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (formData: { fields: IMinPriceChangeRuleValues[] }) => {
    if (minPriceChangeRule) {
      const newValues = formData.fields.map((item) => ({
        priceFrom: Number(item.priceFrom),
        priceTo: Number(item.priceTo),
        decreaseValue: item.decreaseValue ? Number(item.decreaseValue) : null,
        decreasePercent: item.decreasePercent ? Number(item.decreasePercent) : null,
        increaseValue: item.increaseValue ? Number(item.increaseValue) : null,
        increasePercent: item.increasePercent ? Number(item.increasePercent) : null,
      }));

      const updatedMinPriceChangeRule = {
        ...minPriceChangeRule,
        values: newValues,
      };

      dispatch(setMinPriceChangeRule(updatedMinPriceChangeRule));
      dispatch(setCurrentRule(updatedMinPriceChangeRule));
      dispatch(setFormsCommentModalOpened(true));
    }
  };

  const changeActiveStatus = async (status: boolean) => {
    if (minPriceChangeRule) {
      const updatedMinPriceChangeRule = {
        ...minPriceChangeRule,
        active: status,
      };

      dispatch(setCurrentRule(updatedMinPriceChangeRule));
      dispatch(setMinPriceChangeRule(updatedMinPriceChangeRule));

      await dispatch(
        fetchRuleEditAction({
          nodes: modifiedNodeParams(nodesParams),
          rule: modifiedMinPriceChangeRule(status, minPriceChangeRule),
        }),
      );

      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }
  };

  const addValue = () => {
    if (minPriceChangeRule)
      dispatch(
        setMinPriceChangeRule({
          ...minPriceChangeRule,
          values: [...minPriceChangeRule?.values, { ...rulesInitialValues.minPriceChangeRule }],
        }),
      );

    form.insertListItem('fields', { ...rulesInitialValues.minPriceChangeRule });
  };

  const removeValue = (valueIndex: number) => {
    if (minPriceChangeRule) {
      if (minPriceChangeRule?.values.length !== 1) {
        const newValues = minPriceChangeRule?.values.filter((item, index) => index !== valueIndex);

        dispatch(
          setMinPriceChangeRule({
            ...minPriceChangeRule,
            values: newValues,
          }),
        );

        form.removeListItem('fields', valueIndex);
      } else {
        form.setFieldValue('fields.0.priceFrom', 0);
        form.setFieldValue('fields.0.priceTo', 999999);
        form.setFieldValue('fields.0.decreaseValue', '');
        form.setFieldValue('fields.0.decreasePercent', '');
        form.setFieldValue('fields.0.increaseValue', '');
        form.setFieldValue('fields.0.increasePercent', '');
      }
    }
  };

  const deleteRule = async () => {
    if (minPriceChangeRule?.id) {
      await dispatch(fetchDeleteRuleAction([minPriceChangeRule.id]));
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }

    dispatch(setMinPriceChangeRule(null));
    const updatedRuleBlocks = ruleBlocks.map((item) => ({
      ...item,
      checked: item.type === ERuleType.minPriceChange ? false : item.checked,
      shown: item.type === ERuleType.minPriceChange ? false : item.shown,
    }));

    dispatch(setRuleBlocks(updatedRuleBlocks));
  };

  return (
    <PriceRuleBlock>
      <Flex direction="row" justify="space-between">
        {minPriceChangeRule?.inherited || alsoHasInheritedRule ? (
          <Badge color="green" variant="filled">
            Наследуется
          </Badge>
        ) : (
          <Switch
            classNames={{ track: classes.track }}
            checked={minPriceChangeRule?.active ?? false}
            color="dark"
            size="md"
            onChange={(event) => {
              changeActiveStatus(event.currentTarget.checked);
            }}
          />
        )}
        {!(alsoHasInheritedRule || minPriceChangeRule?.inherited) && (
          <ActionIcon
            color="dark"
            size="lg"
            variant="transparent"
            className={classes.actionIcon}
            onClick={() => openDeleteModal(deleteRule)}
          >
            <IconX />
          </ActionIcon>
        )}
      </Flex>
      <Title mt={12} mb={24} order={3}>
        {minPriceChangeRule?.name}
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {minPriceChangeRule?.values.map((item, index) => (
          <Flex direction="row" gap={24} align="flex-end" key={index}>
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              label="РЦ"
              rightSection="₽"
              disabled={!minPriceChangeRule.active || disabled}
              icon={<Text>от</Text>}
              required
              {...form.getInputProps(`fields.${index}.priceFrom`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              rightSection="₽"
              disabled={!minPriceChangeRule.active || disabled}
              icon={<Text>до</Text>}
              required
              {...form.getInputProps(`fields.${index}.priceTo`)}
            />
            <div
              style={{ width: '1px', height: '52px', backgroundColor: 'black', margin: '0' }}
            ></div>
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              label="Граница (снижение)"
              rightSection="₽"
              disabled={!minPriceChangeRule.active || disabled}
              {...form.getInputProps(`fields.${index}.decreaseValue`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              rightSection="%"
              disabled={!minPriceChangeRule.active || disabled}
              {...form.getInputProps(`fields.${index}.decreasePercent`)}
            />
            <div
              style={{ width: '1px', height: '52px', backgroundColor: 'black', margin: '0' }}
            ></div>
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              label="Граница (повышение)"
              rightSection="₽"
              disabled={!minPriceChangeRule.active || disabled}
              {...form.getInputProps(`fields.${index}.increaseValue`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              rightSection="%"
              disabled={!minPriceChangeRule.active || disabled}
              {...form.getInputProps(`fields.${index}.increasePercent`)}
            />
            <ActionIcon
              color="dark"
              size="lg"
              variant="transparent"
              className={classes.actionIcon}
              disabled={!minPriceChangeRule.active || disabled}
              onClick={() => removeValue(index)}
              title="Очистить"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        ))}

        <Flex direction="row" justify="space-between" mt={24}>
          <Button
            leftIcon={<IconPlus size="1.3rem" />}
            sx={(theme) => ({
              color: minPriceChangeRule?.active || disabled ? theme.colors.blue[8] : 'gray',
              paddingLeft: '0px',
              ['&[data-disabled]']: {
                backgroundColor: 'white',
              },
            })}
            variant="subtle"
            disabled={!minPriceChangeRule?.active || disabled}
            onClick={addValue}
          >
            Добавить ещё
          </Button>
          {alsoHasInheritedRule && (
            <Tooltip
              label="Вы не можете редактировать правило, так как уже создано деактивированное правило. Перейдите во вкладку деактивированных правил."
              withArrow
              multiline
              w={350}
              openDelay={500}
              position="left"
            >
              <Flex>
                <Button color="dark.9" disabled={alsoHasInheritedRule}>
                  Редактировать
                </Button>
              </Flex>
            </Tooltip>
          )}
          {minPriceChangeRule?.active && disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} onClick={() => setDisabled(false)}>
              Редактировать
            </Button>
          )}
          {minPriceChangeRule?.active && !disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} type="submit" disabled={!form.isValid()}>
              Сохранить
            </Button>
          )}
        </Flex>
      </form>
    </PriceRuleBlock>
  );
};

export default MinPriceChangeRule;
