import { MRT_SortingState } from 'mantine-react-table';

export const setStatusColor = (value: string): string => {
  switch (value) {
    case 'Новый':
      return 'mariner.6';
    case 'Готов':
    case 'Опубликован':
      return 'chateau-green.5';
    case 'Рассчитан':
    case 'Расчет':
      return 'yellow.7';
    case 'Ошибка':
      return 'burnt-sienna.5';
    default:
      return 'gray.6';
  }
};

export const setValidationStatus = (value: string): string => {
  switch (value) {
    case 'Новый':
      return 'mariner.6';
    case 'Рассчитан':
      return 'chateau-green.5';
    case 'Расчет':
    case 'Частично рассчитан':
      return 'yellow.7';
    case 'Ошибка расчета':
      return 'burnt-sienna.5';
    default:
      return 'gray.6';
  }
};

export const setDetailPlanStatus = (value: string): string => {
  switch (value) {
    case 'error':
      return 'burnt-sienna.5';
    case 'waiting':
      return 'yellow.7';
    case 'approved':
      return 'chateau-green.5';
    default:
      return 'gray.6';
  }
};

export const getDetailPlanStatusName = (value: string): string => {
  switch (value) {
    case 'error':
      return 'Ошибка расчета';
    case 'waiting':
      return 'Ожидает согласования';
    case 'approved':
      return 'Согласован';
    case 'declined':
      return 'Отклонен';
    default:
      return '';
  }
};
export const getPlansSort = (sorting: MRT_SortingState) => {
  if (sorting.length > 0 && sorting[0].id === 'createdAt') return 'created_at';
  if (sorting.length > 0 && sorting[0].id === 'validationStatus') return 'validation_status';
  if (sorting.length > 0 && sorting[0].id === 'processedAt') return 'processed_at';
  return sorting?.[0]?.id ?? 'created_at';
};

export const getOpacity = (selectedItem: string, value: string): number => {
  return !!selectedItem.length && value !== selectedItem ? 0.5 : 1;
};

// у нас 18 типов цветов, когда доходим до 18, тип выбирается с 0
// в каждом типе 10 цветов, округляем вниз, чтобы можно было взять 0 элемент
// для того чтобы оттенки не сильно пересекались
// умножаем получившуюся величину на 2
// всего у нас будет 18 * 5 цветов, что равно 90 колбаскам
export const getBarColor = (
  colors: Record<string, string[]>,
  colorNames: string[],
  value: number,
): string => {
  return colors[colorNames[value % 18]][9 - Math.floor(value / 18) * 2];
};

export const getPossiblyEmptyItemValue = <T>(value: T) => {
  switch (value) {
    case null:
      return '\u2014';
      break;
    case true:
      return 'Да';
      break;
    case false:
      return 'Нет';
      break;
    default:
      return value;
  }
};

export const getReferenceLineColor = (lineName: string) => {
  switch (lineName) {
    case 'ЗЦ':
      return 'blue';
    case 'ЗЦ с бон':
      return 'red';
    case 'ТЦ':
      return 'yellow';
    default:
      return 'green';
  }
};

export const getLabelListValue = (value: string, width: number) => {
  if (width > 150) {
    return value;
  } else if (width > 50 && width < 150) {
    return `${value.split(' ')[0]}...`;
  } else return '';
};
