import { FC } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Flex, Text } from '@mantine/core';

import {
  getDetailPlanStatusName,
  getPossiblyEmptyItemValue,
  setDetailPlanStatus,
} from '@/utils/plans';

import TableBadge from '@/ui/organisms/TableBadge/TableBage';

import { selectDetailPlanInfo } from '@/store/slices/plans/plans';

const useStyles = createStyles((theme) => ({
  titleText: {
    fontSize: '14px',
    color: theme.colors.dark[2],
  },
  infoText: {
    fontSize: '14px',
  },
  plansBadge: {
    padding: '1px 8px',
    height: '24px',
  },
}));

const EditPlanPageTableDrawerInfoTab: FC = () => {
  const { classes } = useStyles();

  const editPlanInfo = useSelector(selectDetailPlanInfo);

  if (!editPlanInfo) return null;

  return (
    <Flex direction="column" gap={16}>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Статус согласования
        </Text>
        <Text>
          {editPlanInfo.status.length ? (
            <TableBadge
              color={setDetailPlanStatus(editPlanInfo.status)}
              text={getDetailPlanStatusName(editPlanInfo.status)}
            />
          ) : (
            '\u2014'
          )}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Наименование товара
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.prodName)}</Text>
      </Flex>

      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          ЖНВЛС
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.jnvls)}</Text>
      </Flex>

      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Рентабельность
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.profitability)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          ID Региона
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.geoId)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Наименование региона
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.geoName)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Торговая марка
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.tradeMark)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Класс
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.class)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'ЗЦ (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.cost)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'ЗЦ с бонусом (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.costWithBonus)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Текущая цена (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.currentPrice)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Новая цена (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.newPrice)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Lock цена (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.lockPrice)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Новая цена: от (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.newPriceFrom)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Новая цена: до (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.newPriceTo)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Кол-во нарушений
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.errorsCount)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Изменение цены
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.priceChangeAbs)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Изменение цены по модулю
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.priceChangeAbsMod)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Процент изменения цены
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.priceChangePercent)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Процент изменения цены по модулю
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.priceChangePercentMod)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Текущая наценка (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.currentMarkup)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Текущая наценка с бонусом (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.currentMarkupWithBonus)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Новая наценка (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.newMarkup)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Новая наценка с бонусом (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.newMarkupWithBonus)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Изменение наценки (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.changeMarkup)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Изменение наценки по модулю (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.changeMarkupAbs)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Изменение наценки с бонусом (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.changeMarkupWithBonus)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Изменение наценки с бонусом по модулю (%)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.changeMarkupWithBonusAbs)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Продажи
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.sales)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Оборот (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.turnover)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Расчетный оборот (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.estimatedTurnover)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          {'Расчетный прирост оборота (\u20BD)'}
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.estimatedTurnoverGrowth)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Комментарий
        </Text>
        <Text className={classes.infoText}>{getPossiblyEmptyItemValue(editPlanInfo.comment)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Тип ЖНВЛС
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.jnvls_type)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Тип ЗЦ
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.costs_type)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Тип Конкурент
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.competitor_type)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Тип Фиксированная цена
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.fix_prices_type)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Тип Фиксированная наценка
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.fix_margin_type)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Тип Новый товар / Ассортиментная матрица
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.assortment_type)}
        </Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.titleText} w={250}>
          Тип Старая цена
        </Text>
        <Text className={classes.infoText}>
          {getPossiblyEmptyItemValue(editPlanInfo.old_prices_type)}
        </Text>
      </Flex>
    </Flex>
  );
};

export default EditPlanPageTableDrawerInfoTab;
